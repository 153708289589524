import React from 'react';
import ContactItem from './ContactItem';
import contactIcon from '../../assets/icons/phone.svg';
import locationIcon from '../../assets/icons/location.svg';
import emailIcon from '../../assets/icons/email.svg';

const contactData = [
  {
    icon: contactIcon,
    title: "Call Us On",
    details: ["+260 965 264 484", "+ 260 979 720 017"],
    iconClass: "w-[43px]"
  },
  {
    icon: locationIcon,
    title: "Address",
    details: ["3078 Foxdale Lusaka Zambia"],
    iconClass: "w-[37px]"
  },
  {
    icon: emailIcon,
    title: "Email us",
    details: ["info@iridiumplus.co.zm", "info@iridiumplus.co.zm"],
    iconClass: "w-[27px]"
  }
];

function ContactSection() {
  return (
    <section data-aos="zoom-in" data-aos-duration="2000" className="flex flex-col items-center justify-center mt-24 mb-16 mx-auto max-w-[831px]">
      <h1 style={{ fontFamily: "roboto, sans-serif" }} className="text-[48px] font-semibold tracking-tighter leading-56 text-center text-slate-900 max-md:text-4xl">
        Let's Chat
      </h1>
      <p style={{ fontFamily: "inter, sans-serif" }} className="mt-7 text-[14px] font-normal leading-17 text-center text-slate-600 w-[640px] max-md:w-full">
        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
      </p>
      <div className="mt-28 max-md:mt-10 w-full">
        <div className="flex gap-5 justify-center max-md:flex-col">
          {contactData.map((item, index) => (
            <ContactItem key={index} {...item} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ContactSection;

import React from "react";

function ScheduleButton() {
  return (
    <button style={{ fontFamily: 'Inter, sans-serif' }} className="px-7 py-3 my-auto text-base tracking-wide not-italic text-white border rounded-lg max-md:px-5 max-sm:mt-3 max-sm:ml-20">
      Schedule Meeting
    </button>
  );
}

export default ScheduleButton;
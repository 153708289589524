import React from "react";

function ContactInfo({ icon, days, hours }) {
  return (
    <div className="flex ">
      <img loading="lazy" src={icon} alt="" className="object-contain shrink-0 aspect-square w-[58px] max-sm:ml-14" />
      <div className="flex flex-col">
        <h2 className="ml-4 text-2xl text-white">{days}</h2>
        <p className="ml-4 self-start mt-5 text-xl text-white">{hours}</p>
      </div>
    </div>
  );
}

export default ContactInfo;
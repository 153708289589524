import React from 'react';

function GetInTouch() {
  return (
    <section className="grid grid-cols-2 text-black max-md:grid-cols-1 max-md:mt-10 max-md:max-w-full">
      {/* Column 1: Heading, description, and contact information */}
      <div style={{ fontFamily: 'roboto, sans-serif' }} className="col-span-2 flex flex-col justify-center">
        <div className='items-start'>
          <h2 className="text-[48px] leading-normal font-semibold max-md:text-[48px] md:text-left">
            Get in touch with our team
          </h2>
        </div>
        <p className="mt-8 text-[20px] font-normal leading-normal not-italic max-md:mt-4 text-center md:text-left">
          Email, Call, or complete the form to learn how IridiumPlus can solve your problem.
        </p>
        
        {/* Contact Information below the paragraph */}
        <div className="grid grid-col-1 gap-4 mt-6 max-md:mt-4">
          <div className="flex items-center gap-2 text-[14.53px]">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e01050f4b0709c0253a8baed6a4f16f971e3f958dc7692856a78dbaf02648f5?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf" className="w-[17px]" alt="Email Icon" />
            <div>info@iridiumplus.co.zm</div>
          </div>
          <div className="flex items-center gap-2 text-[16px] font-medium">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff3030c7ab131f8db75338925f17c16c4616d9837b58fe5fea3d5800c049e7eb?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf" className="w-[17px]" alt="Phone Icon" />
            <div>+260 965 264 484</div>
          </div>
          <div className="flex items-center gap-2 text-[14.53px] leading-normal font-normal">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6236019ca86718f60531834e7d262afb1ba2da4b53fd68adb627fd5b9aaab885?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf" className="w-[17px]" alt="Support Icon" />
            <div>Customer Support</div>
          </div>
        </div>
      </div>

      {/* Column 2: Image */}
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/049cfe50bf52125b90e60ade6baa45cbe6da3caee38cf8db8d87db3120254112?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf" className="col-span-3 mt-10 w-full max-md:mt-6 -ml-60 items-center" alt="Team collaboration" 
      />
    </section>
  );
}

export default GetInTouch;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from 'react-icons/fa'; // Hamburger and Close icons
import iridiumpLogo from '../assets/icons/iridiumplus.svg';
import contactIcon from '../assets/icons/contact.svg';

const Header = () => {
  const [activeLink, setActiveLink] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  // Update the active link when the component mounts or when the route changes
  React.useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  // Toggle menu for mobile view
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="sticky top-0 z-50 flex flex-col justify-center items-center self-stretch px-4 py-3.5 w-full text-[17.6px] leading-5 font-semibold text-black bg-white shadow-md md:px-16">
      <nav className="flex flex-wrap gap-4 justify-between items-center w-full max-w-[1048px]">
        {/* Logo and Text Section */}
        <div className="flex items-center">
          <Link to="/" className="flex flex-col items-center mx-auto">
            <img
              src={iridiumpLogo}
              alt="logo"
              className="w-8 h-auto object-contain"
            />
            <div className="grow self-end text-sm tracking-[2.25px]">
              <span className="text-teal-500">IRIDIUM</span>{" "}
              <span className="text-indigo-950">PLUS</span>{" "}
            </div>
          </Link>
        </div>

        {/* Hamburger Menu Icon for Mobile */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            {isMenuOpen ? (
              <FaTimes className="w-6 h-6 text-teal-500" /> // Close icon
            ) : (
              <FaBars className="w-6 h-6 text-teal-500" /> // Hamburger icon
            )}
          </button>
        </div>

        {/* Navigation Links for large screens */}
        <div className="hidden md:flex md:flex-wrap justify-center items-center space-x-4">
          <Link
            to="/"
            className={`p-2 md:p-4 ${
              activeLink === "/" 
                ? "text-teal-500" 
                : "hover:text-teal-500 hover:border-teal-500 border-transparent"
            }`}
            onClick={() => setActiveLink("/")}
          >
            Home
          </Link>

          <Link
            to="/about"
            className={`p-2 md:p-4 ${
              activeLink === "/about" 
                ? "text-teal-500" 
                : "hover:text-teal-500 hover:border-teal-500 border-transparent"
            }`}
            onClick={() => setActiveLink("/about")}
          >
            About Us
          </Link>

          <Link
            to="/services"
            className={`p-2 md:p-4 ${
              activeLink === "/services" 
                ? "text-teal-500" 
                : "hover:text-teal-500 hover:border-teal-500 border-transparent"
            }`}
            onClick={() => setActiveLink("/services")}
          >
            Services
          </Link>

          <Link
            to="/contactus"
            className={`p-2 md:p-4 ${
              activeLink === "/contactus" 
                ? "text-teal-500" 
                : "hover:text-teal-500 hover:border-teal-500 border-transparent"
            }`}
            onClick={() => setActiveLink("/contactus")}
          >
            Contact
          </Link>

          <Link
            to="/visa-permit"
            className={`p-2 md:p-4 ${
              activeLink === "/visa-permit" 
                ? "text-teal-500" 
                : "hover:text-teal-500 hover:border-teal-500 border-transparent"
            }`}
            onClick={() => setActiveLink("/visa-permit")}
          >
            Visa/Permit
          </Link>
        </div>

        {/* Dropdown Menu for Mobile */}
        {isMenuOpen && (
          <div className="absolute top-[60px] left-0 w-full bg-white shadow-md flex flex-col space-y-2 p-4 md:hidden">
            <Link
              to="/"
              className={`p-2 ${
                activeLink === "/"
                  ? "text-teal-500"
                  : "hover:text-teal-500 hover:border-teal-500 border-transparent"
              }`}
              onClick={() => {
                setActiveLink("/");
                setIsMenuOpen(false);
              }}
            >
              Home
            </Link>

            <Link
              to="/about"
              className={`p-2 ${
                activeLink === "/about"
                  ? "text-teal-500"
                  : "hover:text-teal-500 hover:border-teal-500 border-transparent"
              }`}
              onClick={() => {
                setActiveLink("/about");
                setIsMenuOpen(false);
              }}
            >
              About Us
            </Link>

            <Link
              to="/services"
              className={`p-2 ${
                activeLink === "/services"
                  ? "text-teal-500"
                  : "hover:text-teal-500 hover:border-teal-500 border-transparent"
              }`}
              onClick={() => {
                setActiveLink("/services");
                setIsMenuOpen(false);
              }}
            >
              Services
            </Link>

            <Link
              to="/contactus"
              className={`p-2 ${
                activeLink === "/contactus"
                  ? "text-teal-500"
                  : "hover:text-teal-500 hover:border-teal-500 border-transparent"
              }`}
              onClick={() => {
                setActiveLink("/contactus");
                setIsMenuOpen(false);
              }}
            >
              Contact
            </Link>

            <Link
              to="/visa-permit"
              className={`p-2 ${
                activeLink === "/visa-permit"
                  ? "text-teal-500"
                  : "hover:text-teal-500 hover:border-teal-500 border-transparent"
              }`}
              onClick={() => {
                setActiveLink("/visa-permit");
                setIsMenuOpen(false);
              }}
            >
              Visa/Permit
            </Link>
          </div>
        )}

        {/* Contact Section */}
        <div className="hidden md:flex items-center gap-2.5 text-sm md:text-base font-medium">
          <img
            loading="lazy"
            src={contactIcon}
            alt="Contact"
            className="object-contain shrink-0 w-6 h-6 md:w-8"
          />
          <div className="hidden md:block">
            Any Questions? Call Us <br /> + 260 979 72 001 7
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;

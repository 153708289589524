import React from 'react';
import connectingImg from '../../assets/img/connectingImg.png';

const MissionSection = () => {
  return (
    <section className="px-6  md:px-12 lg:px-20 mt-2 w-full max-w-[1207px] mx-auto text-center max-md:mt-10">
      <div style={{ fontFamily: 'roboto, sans-serif' }} className="flex gap-5 justify-center items-center max-md:flex-col">
        <div className="flex flex-col w-[62%] max-md:w-full">
          <div data-aos="fade-up-right" data-aos-duration="3000" className="flex flex-col items-start text-left font-medium text-black max-md:text-center max-md:items-center">
            <h2 className="text-[20px] font-medium text-zinc-500">OUR MISSION</h2>
            <h3 className="self-stretch mt-14 text-[64px] max-md:mt-10 max-md:text-[48px]">
              Connecting Businesses, Bridging Markets
            </h3>
            <p className="mt-11 text-[16px] leading-normal font-light max-md:mt-10">
              "To connect businesses across borders, bridge cultural and market gaps, and facilitate seamless international trade."
            </p>
          </div>
        </div>
        <div data-aos="flip-up" data-aos-duration="3000"  className="flex flex-col w-[38%] max-md:w-full">
          <img 
            loading="lazy"
            src={connectingImg}
            alt='connecting'
            className="object-contain grow mt-9 w-full aspect-[0.87] max-md:mt-10"
          />
        </div>
      </div>
    </section>
  );
};

export default MissionSection;

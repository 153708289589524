import React from 'react';

function FeatureCard({ number, title, description, bgColor }) {
  return (
    <article className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <div className={`flex flex-col grow items-start px-16 pt-20 pb-10 w-full text-white ${bgColor} max-md:px-5 max-md:max-w-full`}>
        <h3 className="text-9xl max-md:text-4xl">{number}</h3>
        <h4 className="mt-14 text-3xl max-md:mt-10">{title}</h4>
        <p className="mt-7 text-lg font-extralight">{description}</p>
      </div>
    </article>
  );
}

export default FeatureCard;
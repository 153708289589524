import React from "react";
import FeatureCard from "./FeatureCard";
import efficiencyIcon from '../../assets/icons/efficiency.svg'; 
import globalNetworkIcon from '../../assets/icons/globalNetwork.svg'; 
import clientIcon from '../../assets/icons/clients.svg'; 

const features = [
  {
    icon: efficiencyIcon,
    title: "Efficiency & Timeliness",
    description: "Our streamlined processes and experienced team ensure that your transactions are completed promptly and accurately, saving you time and resources.",
    isHighlighted: false
  },
  {
    icon: globalNetworkIcon,
    title: "Global Network & Expertise",
    description: "We leverage our extensive network of partners and associates to provide tailored solutions that meet your specific needs, regardless of the complexity of your trade requirements.",
    isHighlighted: true
  },
  {
    icon: clientIcon,
    title: "Dedicated Client Service",
    description: "Our team is committed to providing personalized support and going the extra mile to ensure your satisfaction.",
    isHighlighted: false
  }
];

function WhyIridiumPlus() {
  return (
    <section className="flex flex-col mt-20 px-4 sm:px-6 lg:px-8">
      <header data-aos="zoom-in" data-aos-duration="2000" className="flex flex-col items-center w-full text-center max-w-[860px] mx-auto">
        <h2 className="text-[48px] not-italic font-semibold tracking-tighter leading-56 text-slate-900 max-md:text-4xl">
          Why IridiumPlus?
        </h2>
        <p className="mt-6 text-[20px] leading-28 text-slate-600 w-full max-w-[640px] mx-auto">
          We offer a comprehensive suite of services designed to streamline your trade journey, from sourcing commodities to navigating complex international transactions.
        </p>
      </header>
      <div className="mt-5 flex flex-col gap-5 sm:flex-row justify-center">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </section>
  );
}

export default WhyIridiumPlus;

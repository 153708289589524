
import React from "react";
import Type from "./Type";
import heroIcon from '../assets/img/hero.png';
import arrowIcon from '../assets/icons/arrow.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

// Initialize AOS
AOS.init();


const Hero = () => {
  return (
    <section className="overflow-hidden  self-stretch pl-20 w-full bg-indigo-950 max-md:pl-5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col w-[44%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto w-full max-md:mt-10 max-md:max-w-full">
            <div data-aos="fade-up-right"  data-aos-duration="2000" className="flex flex-col text-white max-md:max-w-full -mt-5">
              <h1 className=" text-4xl font-semibold tracking-tighter uppercase leading-[59px] max-md:max-w-full">
              
                <span className=" text-6xl">Welcome</span> <br /> to IRIDIUM PLUS{" "}
                <br /> 
                <Type className=" text-6xl" />
              </h1>
              <p className="text-base not-italic text-[15px] font-light leading-24">
                At IRIDIUM PLUS, we believe in empowering businesses to reach
                new heights in the global market. Our comprehensive range of
                services is designed to streamline your trade operations and
                ensure your success.
              </p>
            </div>
            <div data-aos="fade-up-right"  data-aos-duration="3000" className="flex gap-3 items-start self-start mt-5 text-lg font-semibold leading-16.5 text-center text-slate-50 text-[13.5px]">
          
              <button style={{ fontFamily: 'Inter, sans-serif' }} className="flex gap-1.5 justify-center items-center px-4 py-3 bg-teal-500 rounded" >
                <Link to="/services">Get Started</Link> 
                <img
                  loading="lazy"
                  src={arrowIcon}
                  alt=""
                  className="object-contain shrink-0 self-stretch my-auto aspect-[0.93] w-[13px]"
                />
              </button>
             
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[56%] max-md:ml-0 max-md:w-full">
          <div className="flex overflow-hidden flex-col grow h-full w-full bg-white max-md:mt-10 max-md:max-w-full">
            <img
              loading="lazy"
              src={heroIcon}
              alt="Illustration of global trade"
              className="object-contain w-full aspect-[0.95] max-md:max-w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

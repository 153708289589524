import React from 'react';

const TeamMember = ({ name, position }) => {
  return (
    <div style={{ fontFamily: 'roboto, sans-serif' }} className="flex flex-col px-4 pt-36 pb-5 bg-slate-100 min-w-[240px] w-[545px] max-md:pt-24 max-md:max-w-full">
      <h3 className="text-[30px] font-semibold tracking-tight leading-28 text-slate-600 max-md:max-w-full">
        {name}
      </h3>
      <p className="mt-1 text-[16px] leading-24 text-slate-500 max-md:max-w-full">{position}</p>
    </div>
  );
};

export default TeamMember;
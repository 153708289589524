import React from "react";

function FeatureCard({ icon, title, description, isHighlighted }) {
  const cardClasses = isHighlighted
    ? "flex flex-col items-center grow px-8 py-3 w-full text-black hover:text-white hover:bg-teal-500 border-2 border-teal-500 border-solid rounded-2xl max-md:mt-10"
    : "flex flex-col grow px-8 py-5 text-black rounded-2xl hover:text-white hover:bg-teal-500 border-2 border-teal-500 border-solid max-md:mt-10";

  const titleClasses = isHighlighted
    ? "mt-1.5 text-center font-semibold leading-23.4 max-md:mr-1 max-md:ml-2.5"
    : "mt-1 text-[16.73px] font-semibold leading-23.4 text-center"; 

  const descriptionClasses = isHighlighted
    ? "mt-2.5 text-base leading-18.4 text-center" 
    : "mt-4 text-[15.08] leading-18.4 text-center"; 

  return (
    <article className="flex flex-col w-full sm:w-1/2 md:w-1/3 max-md:ml-0">
      <div
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
        className={cardClasses}
      >
        <img loading="lazy" src={icon} alt="" className="object-contain self-center aspect-square w-[54px] md:w-[64px]" />
        <h3 className={`font-roboto ${titleClasses}`}>{title}</h3>
        <p className={`font-roboto ${descriptionClasses}`}>{description}</p>

      </div>
    </article>
  );
}

export default FeatureCard;

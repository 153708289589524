import React from 'react';

const Button = ({ children, variant, icon }) => {
  const baseClasses = "inline-flex gap-2 items-center self-start px-4 py-3 text-base font-semibold whitespace-nowrap rounded-xl min-h-[48px]";
  const variantClasses = {
    outline: "text-black border border-black border-solid",
    primary: "text-white bg-teal-500"
  };

  return (
    <button className={`${baseClasses} ${variantClasses[variant]}`}>
      {icon && <img loading="lazy" src={icon} alt="" className="object-contain shrink-0 w-6 aspect-square" />} {/* Icon placed in front of text */}
      <span>{children}</span> {/* Text wrapped in a span for better accessibility */}
    </button>
  );
};

export default Button;

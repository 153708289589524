import React from 'react';

const ServiceCard = ({ title, description, imageUrl, altText, link, isSelected, onClick }) => {
  return (
    <a
      href={link} 
      onClick={onClick} 
      className={`block cursor-pointer ${isSelected ? 'bg-[#87BDB8]' : 'bg-white'} transition-colors duration-300`}
    >
      <div className="flex items-start gap-5 px-9 py-5 w-full text-black border-b border-gray-400 max-md:flex-col max-md:gap-4 max-md:px-5">
        {/* Title Column */}
        <div className="w-1/3 text-3xl font-semibold">
          {title}
        </div>

        {/* Description Column */}
        <div className="w-1/3 text-xl">
          {description}
        </div>

        {/* Icon Column */}
        <div className="w-1/3 flex justify-end">
          <img 
            loading="lazy" 
            src={imageUrl} 
            alt={altText || 'Service image'} 
            className="object-contain aspect-square w-[52px]" 
          />
        </div>
      </div>
    </a>
  );
};

export default ServiceCard;

import React from 'react';
import Button from './Button';

const QuestionSection = () => {
  return (
    <section className="flex flex-col shadow-[0px_1px_4px_rgba(0,0,0,0.25)] my-10 border border-gray-300 rounded-3xl max-w-4xl mx-auto">
      <div className="py-8 pr-12 pl-5 w-full bg-white rounded-3xl max-md:pr-5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-[65%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-wrap grow gap-3.5 text-lg font-medium max-md:mt-10">
              <img 
                loading="lazy" 
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/aee57de65900f2d768070f8647933e41e762db70b07e62bd30a8d1f7ef211ff4?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf" 
                alt="" 
                className="object-contain shrink-0 aspect-square w-[90px]" 
              />
              <div className="flex flex-col grow shrink-0 my-auto basis-0 w-fit max-md:max-w-full">
                <h2 className="self-start text-black">Still have questions?</h2>
                <p className="mt-2.5 text-zinc-500 max-md:max-w-full">
                  Can't find the answer you're looking for? Please{" "}
                  <a href="/" className="underline text-zinc-500">Chat to our friendly team forum.</a>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[35%] max-md:ml-0 max-md:w-full">
            <div className="flex gap-5 justify-between self-stretch my-auto w-full text-base font-semibold max-md:mt-10">
              <Button variant="outline" className="flex items-center">
                <span>Documentations</span>
                <img 
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/09e8a515967724f8433dab1451e1c16bf5bf1cdd5051ef4f411325adc37af387?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf" 
                  alt="Documentation Icon" 
                  className="w-4 h-4 mr-2 " // Ensure vertical centering with my-auto
                />
                
              </Button>
              <Button variant="primary">Get in touch</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestionSection;

import React, { useState } from 'react';

function ContactForm() {
  // State to manage form data
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  // State to manage form submission status
  const [submitted, setSubmitted] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation (optional)
    if (formData.firstName && formData.email) {
      console.log('Form data:', formData);
      setSubmitted(true);
      // Reset form data if needed
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
      });
    } else {
      alert('Please fill in all required fields.');
    }
  };

  return (
    <form
      className="grid grid-col-1 px-10 py-14 mx-auto mt-7 w-full text-base text-gray-400 bg-white rounded-[33px] border border-gray-300 shadow-md max-md:px-5 max-md:mt-10 max-md:max-w-full"
      onSubmit={handleSubmit}
    >
      <h2 className="self-start text-5xl font-semibold text-black max-md:text-4xl">
        Get in Touch
      </h2>
      <p className="self-start mt-9 text-black">You can reach us anytime</p>

      {/* First and Last Name */}
      <div className="flex gap-4 mt-20 tracking-tight max-md:mt-10 max-md:max-w-full">
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          placeholder="Name"
          className="flex-1 px-3.5 py-3 bg-white rounded-2xl border border-gray-400"
          aria-label="Name"
          required
        />
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          placeholder="Last name"
          className="flex-1 px-3.5 py-3 bg-white rounded-2xl border border-gray-400"
          aria-label="Last name"
        />
      </div>

      {/* Email Field */}
      <div className="flex flex-col justify-center items-start px-3.5 py-3 mt-8 tracking-tight bg-white rounded-2xl border border-gray-400 max-md:pr-5 max-md:max-w-full">
        <div className="flex gap-2 items-start">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/60d20862df6275031a17861b154410226d92ac753807428f8b8315c74263f1bd?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf"
            className="object-contain shrink-0 aspect-square w-[23px]"
            alt=""
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Your email"
            className="bg-transparent flex-1 border-gray-400"
            aria-label="Your email"
            required
          />
        </div>
      </div>

      {/* Phone Field */}
      <div className="flex flex-col justify-center items-start px-3.5 py-3 mt-5 tracking-tight bg-white border border-gray-400 rounded-2xl max-md:pr-5 max-md:max-w-full">
        <div className="flex gap-2 items-start">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f070ae50b3964944a64f8f00fe90137263e3e6ca03023de375f6ec7889b4b950?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf"
            className="object-contain shrink-0 aspect-square w-[23px]"
            alt=""
          />
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="Phone number"
            className="bg-transparent flex-1"
            aria-label="Phone number"
          />
        </div>
      </div>

      {/* Message Field */}
      <textarea
        name="message"
        value={formData.message}
        onChange={handleInputChange}
        placeholder="How can we help?"
        className="px-3.5 pt-3 pb-14 mt-5 tracking-tight bg-white rounded-2xl border border-gray-400 max-md:pr-5 max-md:max-w-full"
        aria-label="How can we help?"
      ></textarea>

      {/* Submit Button */}
      <button
        type="submit"
        className="px-16 py-6 mt-6 text-2xl font-semibold leading-none text-center text-white whitespace-nowrap bg-teal-500 rounded-[33px] max-md:px-5 max-md:max-w-full"
      >
        Submit
      </button>

      {/* Submission Feedback */}
      {submitted && (
        <p className="self-center mt-5 ml-2.5 text-sm font-semibold leading-6 text-center text-green-600">
          Thank you! Your submission has been received.
        </p>
      )}

      <p className="self-center mt-5 ml-2.5 text-sm font-semibold leading-6 text-center text-black">
        <span className="text-gray-400">By contacting us, you agree to our </span>
        <span className="">Terms</span>
        <br />
        <span className="">of service </span>
        <span className="text-gray-400">and</span>
        <span className=""> Privacy Policy</span>
      </p>
    </form>
  );
}

export default ContactForm;

import React from 'react';
import inspiredImg from '../../assets/img/inspiredImg.png';
import innovativeImg from '../../assets/img/innovativeImg.png';

const ValuesSection = () => {
  return (
    <section className="px-4 md:px-8 lg:px-16 mt-28 w-full max-w-[1183px] mx-auto text-center max-md:mt-10">
      <div className="flex gap-5 justify-center items-center max-md:flex-col">
        <div className="flex flex-col w-[61%] max-md:w-full">
          <div className="grow max-md:mt-10">
            <div className="flex gap-5 max-md:flex-col">
              <div data-aos="flip-up" data-aos-duration="3000"  className="flex flex-col w-6/12 max-md:w-full">
                <img 
                  loading="lazy"
                  src={inspiredImg}
                  alt="Company value illustration 1"
                  className="object-contain z-10 w-full aspect-[0.85]"
                />
              </div>
              <div data-aos="flip-left" data-aos-duration="3000"  className="flex flex-col w-6/12 max-md:w-full">
                <img 
                  loading="lazy"
                  src={innovativeImg}
                  alt="Company value illustration 2"
                  className="object-contain mt-40 w-full aspect-[0.85] max-md:mt-10"
                />
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-down-left" data-aos-duration="3000" style={{ fontFamily: 'roboto, sans-serif' }} className="flex flex-col w-[39%] max-md:w-full">
          <div className="flex flex-col items-start mt-14 font-semibold text-black text-left max-md:items-center max-md:text-center max-md:mt-10">
            <h2 className="text-[20px] text-zinc-500">OUR VALUES</h2>
            <h3 className="mt-9 text-[64px] font-semibold max-md:text-[48px]">
              "Inspired <br /> Innovative and Involved"
            </h3>
            <p className="mt-14 text-[16px] font-light max-md:mt-10">
              "Inspired by the potential of international trade, I-PLUS offers innovative solutions and is deeply involved in helping businesses thrive on a global scale."
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValuesSection;

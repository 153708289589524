import React from 'react';
import Testimonial from './Testimonial';
import leeImg from '../../assets/img/leeImg.png';
import saraImg from '../../assets/img/saraImg.png';
import thomasImg from '../../assets/img/thomasImg.png';

const testimonials = [
  {
    name: 'Trenton Duffy',
    role: 'Graduate',
    imageSrc: thomasImg,
  },
  {
    name: 'Nadia Jennings',
    role: 'Student',
    imageSrc: leeImg,
  },
  {
    name: 'Blaine Ramirez',
    role: 'Parent',
    imageSrc: saraImg,
  },
];

const TestimonialSection = () => {
  return (
    <section data-aos="zoom-in" data-aos-duration="3000" style={{ fontFamily: 'roboto, sans-serif' }} className="mt-56 pb-20 text-center max-md:mt-10 px-4 md:px-8 lg:px-16">
      <h2 className="text-[36px] font-semibold tracking-tighter leading-28 text-teal-500 whitespace-nowrap">
        TESTIMONIALS
      </h2>
      <p className="mt-16 text-[36px] font-extralight leading-65 text-slate-900 max-md:mt-10">
        "I-PLUS was the key to my business success. Their<br/> expert guidance and tailored solutions helped me <br/> navigate the complexities of international trade and <br/> achieve my goals."
      </p>

      {/* Testimonials container */}
      <div data-aos="fade-up" data-aos-duration="3000" className="mt-9 mx-auto max-w-[1100px] w-full">
        <div className="flex gap-5 justify-center max-md:flex-col">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="flex flex-col w-[33%] max-md:w-full">
              <Testimonial {...testimonial} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;

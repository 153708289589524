import React from "react";
import { Link } from "react-router-dom";
import iridiumWhiteIcon from '../assets/icons/iridiumWhite.svg'
import facebookIcon from '../assets/icons/Facebook.svg'
import twitterIcon from '../assets/icons/twitter.svg'
import linkedinIcon from '../assets/icons/linkedin.svg'
import phoneIcon from '../assets/icons/phone-white.svg'; 
import emailIcon from '../assets/icons/email-white.svg'; 
import locationIcon from '../assets/icons/location-white.svg'; 

const Footer = () => {
  return (
    <footer className="flex flex-col py-8 px-12 w-full bg-indigo-950 text-slate-50 max-md:px-5 max-md:py-10">
      <div className="flex justify-between items-start flex-wrap w-full max-w-[1050px] mx-auto">
        {/* Logo and Description */}
        <div className="flex flex-col items-start max-w-[300px]">
          <img
            loading="lazy"
            src={iridiumWhiteIcon}
            alt="Iridium Plus Logo"
            className="w-12 h-12 mb-4 items-center"
          />
          <div className="text-[18.46px] font-semibold tracking-[2.95px] mb-4">
            <span className="text-white">IRIDIUM</span> <span className="text-white">PLUS</span>
          </div>
          <p style={{ fontFamily: 'Inter, sans-serif' }} className="text-base leading-24 font-normal  text-[24px]">
            Our comprehensive range of services is designed to streamline your trade operations and ensure your success.
          </p>
          <div className="flex mt-6 space-x-4">
            <img src={facebookIcon} alt="Facebook" className="w-6 h-6" />
            <img src={twitterIcon} alt="Twitter" className="w-6 h-6" />
            <img src={linkedinIcon} alt="LinkedIn" className="w-6 h-6" />
          </div>
        </div>

        {/* Navigation Links */}
        <div style={{ fontFamily: 'DM Sans, sans-serif' }} className="flex flex-wrap justify-between gap-10">
          <div className="flex flex-col text-[16px] leading-40">
            <h3 className=" font-bold text-teal-500">Company</h3>
            <Link to="/" className="mt-6">Home</Link>
            <Link to="/services" className="mt-1.5">Services</Link>
            <Link to="/about" className="mt-1.5">About Us</Link>
            <Link to="/support" className="mt-1.5">Support</Link>
          </div>
          <div className="flex flex-col text-[16px] leading-40">
            <h3 className=" font-bold text-teal-500">Visa Types</h3>
            <Link to="/visa/working" className="mt-6">Working Visa</Link>
            <Link to="/visa/student" className="mt-1.5">Student Visa</Link>
            <Link to="/visa/study" className="mt-1.5">Study Visa</Link>
            <Link to="/visa/family" className="mt-1.5">Family Visa</Link>
          </div>
          <div className="flex flex-col text-[16px] leading-40">
            <h3 className="font-bold text-teal-500">Social</h3>
            <a href="https://instagram.com" className="mt-6">Instagram</a>
            <a href="https://twitter.com" className="mt-1.5">Twitter</a>
            <a href="https://facebook.com" className="mt-1.5">Facebook</a>
            <a href="https://youtube.com" className="mt-1.5">YouTube</a>
          </div>
          <div className="flex flex-col text-[16px] leading-40">
      <h3 className="font-bold text-teal-500">Contact</h3>

      {/* Phone number with phone icon */}
      <div className="flex items-center mt-6">
        <img src={phoneIcon} alt="Phone" className="w-4 h-4 mr-2" /> {/* Local phone icon */}
        <p>+260 979 720 017 / +26 096 526 4484</p>
      </div>

      {/* Email with email icon */}
      <div className="flex items-center mt-4">
        <img src={emailIcon} alt="Email" className="w-4 h-4 mr-2" /> {/* Local email icon */}
        <a href="mailto:info@iridiumplus.co.zm" className="underline lowercase">
          info@iridiumplus.co.zm
        </a>
      </div>

      {/* Address with location icon */}
      <div className="flex items-center mt-4">
        <img src={locationIcon} alt="Location" className="w-4 h-4 mr-2" /> {/* Local location icon */}
        <p>3078 Foxdale Lusaka Zambia</p>
      </div>
    </div>
        </div>
      </div>

      <p className="text-center mt-10 text-sm">
        Copyright 2024. <a href="https://imbra.co.zm" className="text-white underline">imbra</a> All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;

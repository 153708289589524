import React from "react";
import testimonialImg1 from "../assets/img/saraImg.png";
import testimonialImg2 from "../assets/img/leeImg.png";
import testimonialImg3 from "../assets/img/thomasImg.png";

const testimonials = [
  {
    image: testimonialImg1,
    name: "Sarah Jones",
    title: "Marketing Professional",
    quote:
      "IRIDIUM PLUS has been instrumental in helping us expand our business into new markets. Their expertise in international trade has been invaluable. We've seen significant growth and increased revenue since partnering with them.",
    isHighlighted: false,
  },
  {
    image: testimonialImg2,
    name: "Dayla Lee",
    title: "Recent High School Graduate",
    quote:
      "I've been impressed with the level of service provided by IRIDIUM PLUS. Their team is always responsive and goes above and beyond to meet our needs. We've had a smooth experience navigating complex trade regulations with their guidance.",
    isHighlighted: true,
  },
  {
    image: testimonialImg3,
    name: "Thomas Hernandez",
    title: "International Student",
    quote:
      "IRIDIUM PLUS has been a game-changer for our company. Their commodity sourcing services have helped us secure high-quality materials at competitive prices. We've been able to reduce costs and improve our bottom line.",
    isHighlighted: false,
  },
];

const TestimonialCard = ({ image, name, title, quote, isHighlighted }) => {
  const baseClasses =
    "flex flex-col items-center text-center rounded-2xl px-4 py-6";
  const highlightedClasses =
    "mt-4 text-black hover:text-white hover:bg-teal-500 border-2 border-teal-500";
  const normalClasses =
    "text-black hover:text-white hover:bg-teal-500 border-2 border-teal-500";

  return (
    <div
      data-aos="zoom-in"
      data-aos-duration="3000"
      className={`${baseClasses} ${
        isHighlighted ? highlightedClasses : normalClasses
      } w-full md:w-[280px] lg:w-[320px]`}
    >
      <img
        loading="lazy"
        src={image}
        alt={name}
        className="object-contain w-[54px] mb-3"
      />
      <div
        data-aos="fade-up"
        data-aos-duration="3000"
        className="mt-1.5 text-lg font-semibold leading-snug"
      >
        {name}, <br /> {title}
      </div>
      <p className="mt-2.5 text-base leading-5 text-center">"{quote}"</p>
    </div>
  );
};

const Testimonials = () => {
  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="3000"
      className="mt-20 md:mt-10"
    >
      <h2
        style={{ fontFamily: "inter, sans-serif" }}
        className="text-2xl font-semibold tracking-tighter leading-8 text-center text-teal-500 md:text-xl"
      >
        Why IRIDIUM PLUS ?<br />
        <span className="text-teal-500">Learn from Our Clients</span>
      </h2>
      <div style={{ fontFamily: "roboto, sans-serif" }} className="text-base leading-15 text-[9.67px] font-normal mt-8 w-full max-w-[1137px] mx-auto">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

import React from 'react';
import contentIcon from '../../assets/img/Content.png';

const AboutSection = () => {
  return (
    <section data-aos="zoom-in" data-aos-duration="3000" className="flex flex-col items-center self-stretch px-20 pt-10 pb-20 mt-14 w-full text-[20px] text-center text-black bg-teal-500 bg-opacity-30 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div style={{ fontFamily: 'roboto, sans-serif' }} className="flex flex-col max-w-full w-[563px]">
        <p className="max-md:max-w-full">
          We provide the best of services to our clients by maximizing the innovation{" "}
        </p>
        <img loading="lazy" src={contentIcon} alt="Decorative element" className="object-contain self-center mt-16 max-w-full aspect-[4.9] w-[245px] max-md:mt-10" />
      </div>
    </section>
  );
};

export default AboutSection;
// src/pages/ServicePage.jsx
import React from 'react';
import HeroServices from '../components/ServicesComponents/HeroServices';
import Services from '../components/ServicesComponents/Services';
import Features from '../components/ServicesComponents/Features';
import ContactSection from '../components/ContactSection/ContactSection';

const ServicePage = () => {
  return (
    <div>
      <HeroServices />
      <Services />
      <Features />
      <ContactSection />
    </div>
  );
};

export default ServicePage;

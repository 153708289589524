import React from "react";

const TimelineCard = ({ year, title, description }) => {
  return (

       <article style={{ fontFamily: 'roboto, sans-serif' }} className="flex overflow-hidden flex-col shrink justify-center self-stretch my-auto rounded-2xl basis-0 max-w-[258px] min-w-[243px]">
      <header className="overflow-hidden px-16 pt-14 pb-9 w-full text-[50px] not-italic font-black tracking-tighter text-white whitespace-nowrap bg-teal-500 max-md:px-5">
        {year}
      </header>
      <div className="flex overflow-hidden flex-col justify-center p-4 w-full text-base tracking-tighter text-black">
        <h2  className="font-semibold text-[15.66px]">{title}</h2>
        <p className="mt-2.5 font-light text-[15.66px]">{description}</p>
      </div>
    </article>
   
  );
};

export default TimelineCard;

import React, { useState } from 'react';
import companyImg from '../../assets/img/companyImg.png';
import nextIcon from '../../assets/icons/nextIcon.svg';
import previousIcon from '../../assets/icons/previousIcon.svg';

const CompanyHistory = () => {
  // Array of history paragraphs
  const historyParagraphs = [
    "I-PLUS was established in 2012 as a Zambian-based company focused on international trade. Since its inception, I-PLUS has rapidly grown and expanded its services to cater to the evolving needs of global businesses. The company's commitment to excellence and customer satisfaction has enabled it to build strong partnerships and establish a solid reputation in the industry.",
    "This is the first paragraph that talks about our initial phase of growth. I-PLUS started with international trade and rapidly expanded its services globally.",
    "In recent years, I-PLUS has ventured into new areas, establishing itself as a leader in providing top-tier business solutions to clients around the world."
  ];

  // State to hold the current paragraph index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to handle button clicks
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % historyParagraphs.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + historyParagraphs.length) % historyParagraphs.length);
  };

  return (
    <section className="flex overflow-hidden flex-col justify-center items-center self-stretch px-16 py-20 mt-32 w-full bg-white max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div data-aos="flip-right" data-aos-duration="3000" style={{ fontFamily: 'roboto, sans-serif' }} className="flex flex-wrap gap-10 items-start max-md:max-w-full">
        <img loading="lazy" src={companyImg} alt="Company history illustration" className="object-contain aspect-[1.07] min-w-[240px] w-[600px] max-md:max-w-full" />
        <div className="flex flex-col min-w-[240px] w-[410px]">
          <div data-aos="fade-up" data-aos-duration="3000" className="flex flex-col max-w-full w-[410px]">
            <h2 className="flex flex-col w-full text-[39px] font-bold tracking-tighter leading-47 text-slate-900">
              <span className="w-full">Company History</span>
            </h2>
            <p className="mt-8 text-[18px] leading-22 not-italic text-slate-500">
              {historyParagraphs[currentIndex]}
            </p>
          </div>
          {/* Navigation buttons for paragraphs */}
          <div data-aos="fade-up" data-aos-duration="3000" className="flex gap-3 items-start self-start mt-16 max-md:mt-10">
            <button
              onClick={handlePrev}
              className="focus:outline-none"
              disabled={currentIndex === 0} // Disable if at the first paragraph
            >
               <img
                loading="lazy"
                src={previousIcon}
                alt="previous"
                className="object-contain shrink-0 w-9 aspect-square"
              />
            </button>

            <button
              onClick={handleNext}
              className="focus:outline-none"
              disabled={currentIndex === historyParagraphs.length - 1} // Disable if at the last paragraph
            >
               <img
                loading="lazy"
                src={nextIcon}
                alt="Social media icon 2"
                className="object-contain shrink-0 w-9 aspect-square"
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyHistory;

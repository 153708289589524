import React from 'react';
import GetInTouch from '../components/ContactUs/GetInTouch';
import ContactForm from '../components/ContactUs/ContactForm';
import QuestionSection from '../components/ContactUs/QuestionSection';

function ContactPage() {
  return (
    <div className="flex flex-col bg-white">
      <main className="mt-20 w-full max-w-[1292px] max-md:mt-10 max-md:max-w-full">
        {/* Grid layout for responsiveness */}
        <div className="grid grid-cols-1 gap-5 max-md:grid-cols-1 md:grid-cols-2">
          {/* GetInTouch will take 1 full column on mobile and half on medium+ screens */}
          <div className="col-span-1 md:col-span-1">
            <GetInTouch />
          </div>
          {/* ContactForm will also take 1 full column on mobile and the other half on larger screens */}
          <div className="col-span-1 md:col-span-1">
            <ContactForm />
          </div>
        </div>
      </main>
      {/* QuestionSection can be positioned below for all devices */}
      <QuestionSection />
    </div>
  );
}

export default ContactPage;

import React from "react";
import ServiceItem from "./ServiceItem";
import Button from "./Button";
import deliveringImg from '../../assets/img/delivery.png';
import arrowIcon from '../../assets/icons/arrow.svg';
import checkCircleIcon from '../../assets/icons/check-circle.svg';

const services = [
  { icon: checkCircleIcon, text: "Immigration Consultant" },
  { icon: checkCircleIcon, text: "Regional & Global Trade" },
  { icon: checkCircleIcon, text: "Trade Consultancy" },
  { icon: checkCircleIcon, text: "Global Commodity Sourcing" }
];

function DeliveryServices() {
  return (
    <section className="px-6 sm:px-16 lg:px-28 mt-24">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="flex flex-col w-full md:w-6/12">
          <div className="flex flex-col items-start self-stretch my-auto w-full">
            <div data-aos="fade-right" data-aos-duration="3000" className="leading-[1.15] flex flex-col">
              <h1 style={{ fontFamily: 'Inter, sans-serif' }} className="text-3xl md:text-5xl lg:text-6xl font-semibold tracking-tight text-teal-500">
                Delivering<span className="text-teal-500"> Our</span> <br /> Services
              </h1>
              <p style={{ fontFamily: 'Inter, sans-serif' }} className="text-base md:text-lg lg:text-xl text-slate-600 mt-4">
                A comprehensive digital platform to manage assessments, track student progress, and foster a data-driven learning environment.
              </p>
            </div>
            <div data-aos="fade-right" data-aos-duration="3000" className="flex flex-col mt-10 text-xl font-medium text-black max-w-full">
              {services.map((service, index) => (
                <ServiceItem key={index} icon={service.icon} text={service.text} />
              ))}
            </div>
            <div data-aos="fade-right" data-aos-duration="3500" className="mt-6">
              <Button text="See More" icon={arrowIcon} link="/services"/>
            </div>
          </div>
        </div>
        <div data-aos="flip-up" className="flex flex-col w-full md:w-6/12">
          <img 
            loading="lazy" 
            src={deliveringImg} 
            alt="Illustration of our services" 
            className="object-contain rounded-full ml-14 mt-10 items-center max-md:max-w-[300px] max-md:h-[300px]" 
          />
        </div>
      </div>
    </section>
  );
}

export default DeliveryServices;

import React from 'react';
import AboutSection from '../components/AboutUs/AboutSection';
import ValuesSection from '../components/AboutUs/ValuesSection';
import MissionSection from '../components/AboutUs/MissionSection';
import CompanyHistory from '../components/AboutUs/CompanyHistory';
import OurTeam from '../components/AboutUs/OurTeam';
import TestimonialSection from '../components/AboutUs/TestimonialSection';




const AboutPage = () => {
  return (
    <div className="flex overflow-hidden flex-col items-center bg-white">
   
      <main>
        <h1 data-aos="fade-up" data-aos-duration="3000" style={{ fontFamily: 'roboto, sans-serif' }} className="mt-16 text-[64px] leading-normal text-center text-black max-md:mt-10 max-md:text-4xl">
          About Us
        </h1>
        <AboutSection />
        <ValuesSection />
        <MissionSection />
        <CompanyHistory />
        <OurTeam />
        <TestimonialSection />
      </main>
    </div>
  );
};

export default AboutPage;
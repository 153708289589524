import React from "react";
import { Link } from 'react-router-dom';

function Button({ text, icon, link }) {
  return (
    <div className="flex justify-center mt-16 text-lg font-semibold leading-none text-center text-white max-md:mt-10">
      <Link to={link}>
        <button className="flex gap-2 justify-center items-center px-4 py-2 bg-teal-500 rounded-md transition duration-300 ease-in-out transform hover:scale-105">
          <span className="self-stretch my-auto">{text}</span>
          <img loading="lazy" src={icon} alt="" className="object-contain shrink-0 self-stretch my-auto aspect-square w-[18px] max-md:w-[16px]" />
        </button>
      </Link>
    </div>
  );
}

export default Button;

import React from "react";

function ServiceItem({ icon, text }) {
  return (
    <div className="flex items-center mt-8 w-full">
      <div style={{ fontFamily: 'roboto, sans-serif' }} className="flex items-center w-full max-w-[450px]">
        <img
          loading="lazy"
          src={icon}
          alt=""
          className="object-contain w-5 aspect-square mr-4"
        />
        <span className="text-base md:text-lg lg:text-xl text-slate-700">
          {text}
        </span>
      </div>
    </div>
  );
}

export default ServiceItem;

import React, { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";

const Type = () => {
  const [showSecondLine, setShowSecondLine] = useState(false);

  useEffect(() => {
    // Set a timeout to start typing the second line after the first one completes
    const timeout = setTimeout(() => {
      setShowSecondLine(true);
    }, 2500); // Adjust this timeout to sync with the typewriter speed

    return () => clearTimeout(timeout); 
  }, []);

  return (
    <div className="typewriter">
      {/* Second line: Your Global Trade Partner */}
      {showSecondLine && (
        <div>
          <Typewriter
            options={{
              strings: ["Your Global Trade Partner"],
              autoStart: true,
              loop: true,
              delay: 50,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Type;

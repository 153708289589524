import React from "react";
import ContactInfo from "./ContactInfo";
import ScheduleButton from "./ScheduleButton";
import calendarIcon from '../../assets/icons/calendar.svg';

function ScheduleComponent() {
  const contactInfo = {
    icon: calendarIcon,
    days: "Mon - Fri",
    hours: "8 - 5 pm"
  };

  return (
    <section style={{ fontFamily: 'roboto, sans-serif' }} data-aos="zoom-in" data-aos-duration="2500" className="border flex flex-wrap gap-5 justify-between py-6 pr-20 pl-24 mt-48 ml-0 w-full max-w-[900px] bg-gradient-to-r from-[#03AC9C] to-[#231A47] rounded-2xl max-md:px-5 max-md:max-w-full">
  <ContactInfo {...contactInfo} />
  <ScheduleButton />
</section>


  );
}

export default ScheduleComponent;